import React, { createContext, useState, useRef, useEffect } from "react";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import { isMobile } from "mobile-device-detect";

const SocketContext = createContext();

// const socket = io("https://infer.vda.xane.ai", {
//   // forceNew: true,
//   // transports: ["websocket"],
//   // upgrade: true,
//   path: "/socket",
// });

// const socket = io("localhost:5000");
const socket = io.connect("https://infer.vda.xane.ai", { secure: true });
// const socket = io("https://infer.vda.xane.ai");
// const socket = io("http://13.126.97.171:8089/");

const ContextProvider = ({ children }) => {
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [pauseStream, setPauseStream] = useState(true);
  const [isCalling, setIsCalling] = useState(false);

  const [name, setName] = useState("");
  const [call, setCall] = useState({});
  const [me, setMe] = useState("");

  const myCanvas = useRef();
  const userCanvas = useRef();

  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: "environment",
        },
        audio: true,
      })
      .then((currentStream) => {
        setStream(currentStream);

        myVideo.current.srcObject = currentStream;
      });

    socket.on("me", (id) => setMe(id));

    socket.on("callUser", ({ from, name: callerName, signal }) => {
      setCall({ isReceivingCall: true, from, name: callerName, signal });
    });
  }, []);

  const handlePauseStream = async ({ toggle, emit, canvasReference }) => {
    console.log(toggle, emit, canvasReference);
    // if (toggle === true) {
    //   const currentStream = stream;
    //   const videoTrack = currentStream.getVideoTracks()[0];
    //   console.log(videoTrack);
    //   // videoTrack.stop();
    //   currentStream.removeTrack(videoTrack);
    //   setStream(currentStream);
    // } else {
    //   navigator.mediaDevices
    //     .getUserMedia({ video: true, audio: true })
    //     .then((currentStream) => {
    //       setStream(currentStream);

    //       myVideo.current.srcObject = currentStream;
    //     });
    // }
    const canvas =
      canvasReference === "myCanvas" ? myCanvas.current : userCanvas.current;
    const videoReference =
      canvasReference === "myCanvas" ? myVideo.current : userVideo.current;

    var ctx = canvas.getContext("2d");
    if (toggle === true) {
      ctx.drawImage(
        videoReference,
        0,
        0
        // videoReference.videoHeight,
        // videoReference.videoWidth
      );
    } else {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    if (emit) {
      socket.emit("pauseVideoStream", {
        toggle: toggle,
        emit: false,
        canvasReference:
          canvasReference === "myCanvas" ? "userCanvas" : "myCanvas",
      });
    }
  };
  socket.on("pauseVideoStream", handlePauseStream);
  // useEffect(() => {
  //   if (myVideo.current) {
  //     const w = videoRef.current.videoWidth;
  //     const h = videoRef.current.videoHeight;
  //     const myCan = myCanvas.current;
  //     const userCan = userCanvas.current;
  //     myCan.height = h;
  //     userCan.width = w;
  //   }
  // }, [myVideo]);

  useEffect(() => {
    let h = 640;
    let w = 480;
    if (isMobile) {
      h = 640;
      w = 480;
    }
    if (myCanvas.current) {
      const myCan = myCanvas.current;
      myCan.height = h;
      myCan.width = w;
    }
    if (userCanvas.current) {
      const userCan = userCanvas.current;
      userCan.height = h;
      userCan.width = w;
    }
  });

  const answerCall = () => {
    setCallAccepted(true);

    const peer = new Peer({ initiator: false, trickle: false, stream });

    peer.on("signal", (data) => {
      socket.emit("answerCall", { signal: data, to: call.from });
    });

    peer.on("stream", (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    peer.signal(call.signal);

    connectionRef.current = peer;
  };

  const callUser = (id) => {
    setIsCalling(!!id);
    console.log(id);
    const peer = new Peer({ initiator: true, trickle: false, stream });

    peer.on("signal", (data) => {
      socket.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
        name: "Technician",
      });
    });

    peer.on("stream", (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    socket.on("callAccepted", (signal) => {
      setCallAccepted(true);

      peer.signal(signal);
    });

    connectionRef.current = peer;
  };

  const leaveCall = () => {
    setCallEnded(true);

    connectionRef.current.destroy();

    window.location.reload();
  };

  let current = {
    myCanvas: { color: "red" },
    userCanvas: { color: "#90EE90" },
  };

  // const getCoordinatesToDraw = () => socket.on("drawing", onDrawingEvent);
  // socket.on("drawing", onDrawingEvent);
  socket.on("drawing", onDrawingEvent);

  function onDrawingEvent(data) {
    const canvas =
      data.canvasReference === "myCanvas"
        ? myCanvas.current
        : userCanvas.current;

    const w = canvas.width;
    const h = canvas.height;

    drawLine(
      data.x0 * w,
      data.y0 * h,
      data.x1 * w,
      data.y1 * h,
      data.color,
      false,
      data.canvasReference
    );
  }

  const drawLine = async (x0, y0, x1, y1, color, emit, canvasReference) => {
    console.log(x0, y0, x1, y1, color, emit, canvasReference);
    const canvas =
      canvasReference === "myCanvas" ? myCanvas.current : userCanvas.current;
    if (canvas) {
      const context = canvas.getContext("2d");
      context.beginPath();
      context.moveTo(x0, y0);
      context.lineTo(x1, y1);
      context.strokeStyle = color;
      context.lineWidth = 2;
      context.stroke();
      context.closePath();
    }

    if (!emit) {
      return;
    }
    var w = canvas.width;
    var h = canvas.height;

    await socket.emit("drawing", {
      x0: x0 / w,
      y0: y0 / h,
      x1: x1 / w,
      y1: y1 / h,
      color: color,
      canvasReference:
        canvasReference === "myCanvas" ? "userCanvas" : "myCanvas",
    });
    // await getCoordinatesToDraw();
  };

  const onMyVideoLoaded = (e) => {
    // if (myVideo.current && myVideo.current.videoHeight) {
    //   const w = myVideo.current.videoWidth;
    //   const h = myVideo.current.videoHeight;
    //   // console.log(e.target.clientHeight, w, h);
    //   if (myCanvas.current) {
    //     const myCan = myCanvas.current;
    //     myCan.height = h;
    //     myCan.width = w;
    //   }
    //   setTimeout(() => {
    //     onUserVideoLoaded();
    //   }, 100);
    // }
  };
  const onUserVideoLoaded = (e) => {
    // console.log(
    //   userCanvas.current &&
    //     userCanvas.current.height &&
    //     userCanvas.current.height,
    //   myCanvas.current.height
    // );
    // let myTimeout;
    // if (
    //   userCanvas &&
    //   myCanvas &&
    //   userCanvas.current &&
    //   userCanvas.current.height &&
    //   myCanvas.current.height &&
    //   userCanvas.current.height === myCanvas.current.height
    // ) {
    //   clearTimeout(myTimeout);
    //   return;
    // }
    // if (userVideo.current && userVideo.current.videoHeight) {
    //   const w = userVideo.current.videoWidth;
    //   const h = userVideo.current.videoHeight;
    //   // const w = e.target.clientWidth;
    //   // const h = e.target.clientHeight;
    //   // console.log(e.target.clientHeight, w, h);
    //   console.log(w, h, "###############################################");
    //   if (userCanvas.current) {
    //     const userCan = userCanvas.current;
    //     userCan.height = h;
    //     userCan.width = w;
    //   }
    //   myTimeout = setTimeout(() => {
    //     onUserVideoLoaded();
    //   }, 100);
    // }
  };

  return (
    <SocketContext.Provider
      value={{
        call,
        isCalling,
        callAccepted,
        myVideo,
        userVideo,
        myCanvas,
        userCanvas,
        stream,
        name,
        setName,
        callEnded,
        me,
        callUser,
        leaveCall,
        answerCall,
        current,
        drawLine,
        handlePauseStream,
        onMyVideoLoaded,
        onUserVideoLoaded,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
