import React, { useState, useContext } from "react";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Container,
  Paper,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Assignment, Phone, PhoneDisabled } from "@mui/icons-material";
import { makeStyles } from "@mui/material/styles";

import { SocketContext } from "../Context";

const Sidebar = ({ children }) => {
  const {
    me,
    callAccepted,
    name,
    setName,
    callEnded,
    leaveCall,
    callUser,
    handlePauseStream,
  } = useContext(SocketContext);
  const [idToCall, setIdToCall] = useState("");
  // const classes = useStyles();

  return (
    <Container
      sx={(theme) => ({
        padding: 0,

        [theme.breakpoints.down("xs")]: {
          width: "80%",
        },
      })}
    >
      <Paper
        elevation={10}
        sx={(theme) => ({
          height: "100% important",
          border: "2px solid black",
          padding: 1,
        })}
      >
        <form
          sx={(theme) => ({ display: "flex", flexDirection: "column" })}
          noValidate
          autoComplete="off"
        >
          <Grid
            container
            sx={(theme) => ({
              width: "100%",
              [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
              },
            })}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={(theme) => ({
                padding: 1,
              })}
            >
              {/* <Typography gutterBottom variant="h6">
                Account Info
              </Typography>
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              /> */}
              <CopyToClipboard
                text={me}
                sx={(theme) => ({
                  marginTop: 1,
                })}
              >
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<Assignment fontSize="large" />}
                >
                  Copy Your ID
                </Button>
              </CopyToClipboard>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={(theme) => ({
                padding: 1,
              })}
            >
              <Typography gutterBottom variant="h6">
                Make a call
              </Typography>
              <TextField
                label="ID to call"
                value={idToCall}
                onChange={(e) => setIdToCall(e.target.value)}
                fullWidth
              />

              {callAccepted && !callEnded ? (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<PhoneDisabled fontSize="large" />}
                  fullWidth
                  onClick={leaveCall}
                  sx={(theme) => ({
                    marginTop: 1,
                  })}
                >
                  Hang Up
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Phone fontSize="large" />}
                  fullWidth
                  onClick={() => callUser(idToCall)}
                  sx={(theme) => ({
                    marginTop: 1,
                  })}
                >
                  Call
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
        {children}
      </Paper>
    </Container>
  );
};

export default Sidebar;
