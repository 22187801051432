import React, { useContext } from "react";
import { Grid, Typography, Paper, Box, Button } from "@mui/material";
import { io } from "socket.io-client";

// const socket = io("http://localhost:5000");
import { SocketContext } from "../Context";

// const useStyles = makeStyles((theme) => ({
//   video: {},
//   gridContainer: {},
//   paper: {},
// }));

const VideoPlayer = () => {
  const {
    name,
    isCalling,
    callAccepted,
    myVideo,
    userVideo,
    myCanvas,
    userCanvas,
    callEnded,
    stream,
    call,
    current,
    drawLine,
    handlePauseStream,
    onMyVideoLoaded,
    onUserVideoLoaded,
  } = useContext(SocketContext);

  const userCanvasRef = React.useRef(null);
  let drawing = {
    myCanvas: false,
    userCanvas: false,
  };

  function onMouseDown(e, canvasReference) {
    const rect =
      canvasReference === "myCanvas"
        ? myCanvas.current.getBoundingClientRect()
        : userCanvas.current.getBoundingClientRect();

    drawing[canvasReference] = true;
    current[canvasReference].x =
      e.clientX - rect.left || e.touches[0].clientX - rect.left;
    current[canvasReference].y =
      e.clientY - rect.top || e.touches[0].clientY - rect.top;
  }

  function onMouseUp(e, canvasReference, event) {
    const rect =
      canvasReference === "myCanvas"
        ? myCanvas.current.getBoundingClientRect()
        : userCanvas.current.getBoundingClientRect();
    // e.preventDefault();
    console.log(canvasReference, e.changedTouches, e);
    if (!drawing[canvasReference]) {
      return;
    }
    drawing[canvasReference] = false;
    drawLine(
      current[canvasReference].x,
      current[canvasReference].y,
      e.clientX - rect.left || e.changedTouches[0].clientX - rect.left,
      e.clientY - rect.top || e.changedTouches[0].clientY - rect.top,
      current[canvasReference].color,
      true,
      canvasReference
    );
  }
  function onMouseMove(e, canvasReference) {
    const rect =
      canvasReference === "myCanvas"
        ? myCanvas.current.getBoundingClientRect()
        : userCanvas.current.getBoundingClientRect();
    console.log(canvasReference);
    if (!drawing[canvasReference]) {
      return;
    }
    drawLine(
      current[canvasReference].x,
      current[canvasReference].y,
      e.clientX - rect.left || e.changedTouches[0].clientX - rect.left,
      e.clientY - rect.top || e.changedTouches[0].clientY - rect.top,
      current[canvasReference].color,
      true,
      canvasReference
    );
    current[canvasReference].x =
      e.clientX - rect.left || e.touches[0].clientX - rect.left;
    current[canvasReference].y =
      e.clientY - rect.top || e.touches[0].clientY - rect.top;
  }

  // limit the number of events per second
  function throttle(callback, delay, canvasReference) {
    var previousCall = new Date().getTime();
    return function () {
      var time = new Date().getTime();

      if (time - previousCall >= delay) {
        previousCall = time;

        callback.apply(null, [...arguments, canvasReference]);
      }
    };
  }

  function timerCallback() {
    const VCanvas = userCanvasRef.current;

    requestAnimationFrame(() => {
      let vRef = myVideo.current;

      const w = vRef.videoWidth;
      const h = vRef.videoHeight;
      VCanvas.width = w;
      VCanvas.height = h;
    });
  }

  return (
    <Grid
      container
      sx={(theme) => ({
        justifyContent: "center",

        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
        },
      })}
    >
      {stream && (
        <Paper
          sx={(theme) => ({
            padding: "10px",
            border: "2px solid black",
            margin: "10px",

            display: isCalling && callAccepted ? "block" : "none",
          })}
        >
          <Grid item xs={12} md={12}>
            <Typography variant="h5" gutterBottom>
              {name || "Name"}
            </Typography>
            <Button
              onClick={() =>
                handlePauseStream({
                  toggle: true,
                  emit: true,
                  canvasReference: "myCanvas",
                })
              }
            >
              Pause
            </Button>
            <Button
              onClick={() =>
                handlePauseStream({
                  toggle: false,
                  emit: true,
                  canvasReference: "myCanvas",
                })
              }
            >
              Resume
            </Button>
            <Box
              sx={{
                position: "relative",
                height: "640px !important",
                width: "480px !important",
                borderL: "2px solid black",
              }}
            >
              <canvas
                ref={myCanvas}
                onMouseDown={(e) => onMouseDown(e, "myCanvas")}
                onMouseUp={(e) => onMouseUp(e, "myCanvas")}
                onMouseOut={(e) => onMouseUp(e, "myCanvas")}
                onMouseMove={throttle(onMouseMove, 10, "myCanvas")}
                onTouchStart={(e) => onMouseDown(e, "myCanvas")}
                onTouchEnd={(e) => onMouseUp(e, "myCanvas", "onTouchEnd")}
                onTouchCancel={(e) => onMouseUp(e, "myCanvas", "onTouchCancel")}
                onTouchMove={throttle(onMouseMove, 10, "myCanvas")}
                style={{ position: "absolute", zIndex: "2" }}
              ></canvas>
              <video
                playsInline
                muted
                ref={myVideo}
                style={{ width: "100%" }}
                autoPlay
                onLoadedMetadata={(e) => {
                  onMyVideoLoaded(e);
                }}
              />
            </Box>
          </Grid>
        </Paper>
      )}
      {callAccepted && !callEnded && (
        <Paper
          sx={(theme) => ({
            padding: "10px",
            border: "2px solid black",
            margin: "10px",
            display: isCalling === false && callAccepted ? "block" : "none",
          })}
        >
          <Grid item xs={12} md={12}>
            <Typography variant="h5" gutterBottom>
              {call.name || "Name"}
            </Typography>
            <Button
              onClick={() =>
                handlePauseStream({
                  toggle: true,
                  emit: true,
                  canvasReference: "userCanvas",
                })
              }
            >
              Pause
            </Button>
            <Button
              onClick={() =>
                handlePauseStream({
                  toggle: false,
                  emit: true,
                  canvasReference: "userCanvas",
                })
              }
            >
              Resume
            </Button>
            <Box
              sx={{
                position: "relative",
                height: "640px !important",
                width: "480px !important",
              }}
            >
              <canvas
                ref={userCanvas}
                // height="480"
                // width="640"
                onMouseDown={(e) => onMouseDown(e, "userCanvas")}
                onMouseUp={(e) => onMouseUp(e, "userCanvas")}
                onMouseOut={(e) => onMouseUp(e, "userCanvas")}
                onMouseMove={throttle(onMouseMove, 10, "userCanvas")}
                onTouchStart={(e) => onMouseDown(e, "userCanvas")}
                onTouchEnd={(e) => onMouseUp(e, "userCanvas")}
                onTouchCancel={(e) => onMouseUp(e, "userCanvas")}
                onTouchMove={throttle(onMouseMove, 10, "userCanvas")}
                style={{ position: "absolute", zIndex: "2" }}
              ></canvas>
              <video
                playsInline
                ref={userVideo}
                style={{ width: "100%" }}
                autoPlay
                onLoadedMetadata={(e) => {
                  onUserVideoLoaded(e);
                }}
                // onLoadedMetadata={() => {
                //   timerCallback();
                // }}
                // className={classes.video}
              />
            </Box>
          </Grid>
        </Paper>
      )}
    </Grid>
  );
};

export default VideoPlayer;
