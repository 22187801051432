import React from "react";
import { Typography, AppBar, Box } from "@mui/material";
// import { makeStyles } from "@mui/material/styles";

import VideoPlayer from "./Components/VideoPlayer";
import Sidebar from "./Components/Sidebar";
import Notifications from "./Components/Notifications";

// const useStyles = makeStyles((theme) => ({
//   appBar: {},
//   image: {
//     marginLeft: "15px",
//   },
// }));

const App = () => {
  // const classes = useStyles();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxHeight: "100vh",
        width: "100%",
        // overflow: "hidden",
        // border: "2px solid red",
      }}
    >
      <AppBar
        sx={(theme) => ({
          borderRadius: "1%",
          marginTop: "1%",
          display: "flex",
          maxHeight: "10vh",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          // width: "600px",
          width: "40%",
          border: "2px solid black",

          [theme.breakpoints.down("xs")]: {
            width: "90%",
          },
        })}
        position="static"
        color="inherit"
      >
        <Typography
          variant="h2"
          align="center"
          sx={{ border: "2px solid black", width: "100%", fontSize: "1.4rem" }}
        >
          Virtual Inspection
        </Typography>
      </AppBar>
      <Box sx={{ maxHeight: "80vh" }}>
        <VideoPlayer />
        <Sidebar>
          <Notifications />
        </Sidebar>
      </Box>
    </Box>
  );
};

export default App;
